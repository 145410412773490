import { template as template_cc685d9020f845dabdd702d507e5457e } from "@ember/template-compiler";
const FKControlMenuContainer = template_cc685d9020f845dabdd702d507e5457e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
