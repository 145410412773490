import { template as template_68bf89d902d544e5980620c1797c1227 } from "@ember/template-compiler";
const WelcomeHeader = template_68bf89d902d544e5980620c1797c1227(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
