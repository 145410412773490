import { template as template_613191a111944f2a89aaa61dc6b146dd } from "@ember/template-compiler";
const SidebarSectionMessage = template_613191a111944f2a89aaa61dc6b146dd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
