import { template as template_110d7897afe84eb69578cb0cb175c921 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_110d7897afe84eb69578cb0cb175c921(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_110d7897afe84eb69578cb0cb175c921(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_110d7897afe84eb69578cb0cb175c921(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
